import { FC } from 'react'

import { cva } from 'class-variance-authority'

type IconArrowDownProps = {
  width?: string
  height?: string
  color?: 'gray' | 'brand'
}

const colorVariants = cva(null, {
  variants: {
    color: {
      gray: 'stroke-[#999999]',
      brand: 'stroke-brand',
    },
  },
})

export const IconArrowDown: FC<IconArrowDownProps> = ({
  width = '16',
  height = '16',
  color = 'gray',
}) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={colorVariants({ color })}
        d="M4 6L8 10L12 6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </svg>
  )
}
